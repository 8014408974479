<template>
  <v-container class="d-flex flex-row flex-nowrap login-page-container pa-0" fluid>
    <v-img class="login-image" :lazy-src="require('@/Assets/Images/Interface/planete-online-agencewaow-1949-64p.webp')" :src="require('@/Assets/Images/Interface/planete-online-agencewaow-1949-1680x960.webp')" alt=""></v-img>
    <div class="black-opaque">
      <v-card class="reset-form white elevation-24 pa-8 text-center" width="480">

        <v-img height="50" contain class="mb-8" :src="require('@/Assets/Images/Logo/logo-planete-online.svg')"></v-img>

        <v-card-title>Réinitialisation du mot de passe</v-card-title>
        <v-card-subtitle class="text-justify">Cela fait plus de 30 jours que vous ne vous êtes pas connecté sur <b>planete-online</b>. Par mesure de sécurité, nous vous demandons de modifier votre mot de passe. Merci de votre compréhension.</v-card-subtitle>

        <v-container fluid class="text-center">
          <v-text-field
              name="new-password"
              type="password"
              filled
              label="Nouveau mot de passe"
          ></v-text-field>

          <v-text-field
              name="new-password"
              type="password"
              filled
              label="Confirmation mot de passe"
          ></v-text-field>

          <v-btn color="info">Enregistrer</v-btn>
        </v-container>

      </v-card>
    </div>

  </v-container>
</template>

<script>
export default {
name: "EditPassword"
}
</script>

<style scoped>
.login-page-container {
  background: black;
}

.login-image {
  -webkit-transform: scaleX(-1.05);
  transform: scaleX(-1.05);
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.black-opaque {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.5);
  z-index: 1000;
}

.reset-form {
  /*background-image: url("/images/interface/background-card.png");*/
  /*background-position: -2em 1.8em;*/
  /*background-size: 118%;*/
}
</style>